import React from "react";
import Slider from "react-slick";

import pool from "images/homeslider/pool.jpg";
import front from "images/homeslider/front.jpg";
import near_pool from "images/homeslider/near_pool.jpg";
const slides = [
  {
    label: "front",
    imgPath: front,
  },
  {
    label: "pool",
    imgPath: pool,
  },
  {
    label: "near_pool",
    imgPath: near_pool,
  },
];

const MainSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 3000,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: true,
    fade: true,
  };

  return (
    <Slider {...settings}>
      {slides.map((slide, idx) => (
        <div key={idx} className="image-container h-full w-full md:h-[680px]">
          <img
            src={slide.imgPath}
            alt={slide.label}
            className="w-full h-full object-cover"
          />
          <div className="caption">
            <h5 className="mb-2 font-bold text-2xl md:text-3xl text-center uppercase">
              Escape to Tranquility
              <span className="absolute mt-6 inset-0 flex justify-center">
                <span className="border-t-2 border-white w-1/6 mt-5 md:mt-7 lg:mt-8"></span>
              </span>
            </h5>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default MainSlider;
