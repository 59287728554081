import React from "react";
import MainSlider from "./MainSlider";

function HeroSection() {
  return (
    <div className=" flex flex-col items-center justify-center">
      <div className="w-full mb-10 ">
        <MainSlider />
      </div>
      {/* <div className="title-style-2">
        <p className="text-center py-5 pt-5 text-xl md:text-3xl font-DosisBold tracking-widest headerRed">
          WELCOME TO PEIT THEM RESORT
        </p>
        <hr />
      </div>
      <p className="w-full pt-10 px-2 text-left md:text-center text-lg md:text-xl">
        The Hotel Yalana in Laitumkhrah is a unique and affordable hotel in
        Shillong. <br />
        Hotel Yalana has two Conference Halls, an excellent restaurant, the De
        Oval Bar and spacious parking space. <br /> Located in the heart of
        Shillong, Hotel Yalana has more than 36 standard, family and deluxe
        rooms for your comfort
      </p> */}
    </div>
  );
}

export default HeroSection;
