import React from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import Header from "./components/Header";
import { BrowserRouter } from "react-router-dom";
import Router from "router";
import Footer from "components/Footer";
function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>Peit Them Resort</title>
        <meta name="description" content="Peit Them Resort" />
      </Helmet>
      <Header />
      <Router />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
