import recep from "../../images/reception.jpg";
import { cn } from "lib/utils";
import { Card } from "components/ui/card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInr } from "@fortawesome/free-solid-svg-icons";
import { useGetImages } from "hooks/useGetImages";
import { useCallback, useEffect, useState } from "react";
import { ImageWithLoader } from "components/image-with-loading";

type Props = {
  className?: string;
  id?: number;
};
export const RoomImage = ({ className, id }: Props) => {
  const [imageData, setImageData] = useState<{ url: string; rate: number }>({
    url: recep,
    rate: 0,
  });
  const { images, refetch } = useGetImages({
    prefetch: !!id && id !== 0 ? true : false,
    url: `https://booking.peitthemresort.com/api/getroomimagesbyid/${id}`,
  });

  const style = cn(
    "min-w-full w-full h-full aspect-video md:aspect-square max-w-[500px] max-h-[500px] object-cover min-h-full",
    className
  );

  const updateImageData = useCallback(() => {
    const selectedImage = images?.find((img) => img.room_id === id);
    if (selectedImage) {
      setImageData({
        url: selectedImage.image ?? recep,
        rate: selectedImage.rate ?? 0,
      });
    }
  }, [images, id]);

  useEffect(() => {
    if (images) {
      updateImageData();
    }
  }, [images, updateImageData]);

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, refetch]);

  return (
    <Card className="relative flex flex-col w-full h-full rounded-md shadow-none">
      <div className="w-full h-full">
        <ImageWithLoader
          src={imageData.url}
          alt="room_image"
          className={style}
        />
      </div>
      {imageData.rate > 0 && (
        <Card className="absolute top-0 right-0 flex flex-col items-end justify-start p-2 border-none rounded-none shadow-none pre-header rounded-tl-md text-end">
          <div>
            <p className="text-lg font-semibold tracking-wider lg:text-xl ">
              <FontAwesomeIcon icon={faInr} className="text-md" />
              {imageData.rate}/-
            </p>
          </div>
          {/* <div className="pt-1">
            <p className="text-sm font-bold md:text-base lg:text-md text-end">
              <FontAwesomeIcon icon={faBed} />
              &nbsp;
              {rooms.find((room) => room.value === selectedRoom)?.desc}
            </p>
          </div> */}
        </Card>
      )}
    </Card>
  );
};
