import { useCallback, useEffect, useState } from "react";
import Room from "./Room";
import axios from "axios";
import { toast } from "components/ui/use-toast";
interface RoomT {
  id: number;
  name: string;
  price: string;
  image: string;
}

export default function RoomList() {
  const [rooms, setRooms] = useState<RoomT[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getRooms = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://booking.peitthemresort.com/api/roomcategory"
      );
      const data: RoomT[] = res.data;
      setRooms(data);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch rooms",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getRooms();
  }, [getRooms]);

  return (
    <div className="h-full md:py-20 pb-10 explore-room-bg">
      <div className="mx-1 flex flex-col space-y-10">
        <div className="title-style-2">
          <p className="pb-2 text-xl text-center md:text-3xl font-DosisBold brown-color">
            EXPLORE ROOMS
          </p>
          <hr />
        </div>
        <div className="grid w-full h-full grid-cols-12 gap-5 place-items-center">
          {/*  add skeleton with loading state would be better */}

          {rooms &&
            rooms.map((room, idx) => (
              <div
                key={idx}
                className="flex w-full h-full col-span-full sm:col-span-6 lg:col-span-4"
              >
                <Room
                  label={room.name}
                  rate={room.price}
                  url={room.image}
                  id={room.id}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
