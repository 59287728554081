import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Button } from "./ui/button";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
export default function Header() {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/bookroom");
  };

  return (
    <>
      <div className="pre-header flex items-center ">
        <p className="pl-2 md:pl-20 text-xs md:text-sm py-2 uppercase">
          <FontAwesomeIcon icon={faLocationDot} /> Peit Them Resort, Pongtung,
          Meghalaya 793109
        </p>
      </div>
      <nav className="w-full shadow-lg main-header h-16 pl-4 md:h-24 md:pl-20">
        <div className="mx-auto flex justify-between items-center py-4">
          <div className="flex flex-col">
            <a href="/" className="text-xl md:text-4xl uppercase brown-color">
              Peit Them <span className="green-text">Resort</span>
            </a>
          </div>

          <div className=" gap-5 md:flex items-center justify-end pr-5">
            <Button
              variant="outline"
              onClick={handleSubmit}
              className="btn font-DosisMedium px-3 md:px-4 py-1 headerRed rounded-xl text-sm"
              style={{ border: "1px solid #82b2b8" }}
            >
              <FontAwesomeIcon icon={faCalendarAlt} />
              &nbsp; Book Room
            </Button>
          </div>
        </div>
      </nav>
    </>
  );
}
