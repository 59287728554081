export default function Footer() {
  return (
    <div className="pre-header text-white md:px-10 px-2 py-8 md:py-10">
      <div className="flex justify-center md:justify-between  md:flex-row flex-col">
        <p className="hidden md:block text-sm md:text-2xl uppercase">
          Peit Them Resort
        </p>
        <div>
          <p className="uppercase text-md md:text-2xl mb-1 md:mb-3">
            Contact Us
          </p>
          <p className="mb-2 font-InterRegular text-sm md:text-md">
            +91 6009293737 / 7629040065
            <br />
            yalana.hotel@rediffmail.com
          </p>
        </div>
        <div className="px-2" id="contact">
          <p className="uppercase text-md md:text-2xl mb-1 md:mb-3 ">
            Location
          </p>
          <p className="mb-2 font-InterRegular text-sm md:text-md">
            Peit Them Resort
            <br />
            Pongtung, Meghalaya - 793 109
          </p>
        </div>
      </div>
    </div>
  );
}
