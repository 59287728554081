import React from "react";
import pool from "images/facilities/pool.jpg";
import parking from "images/facilities/parking.jpg";
import bonfire from "images/facilities/bonfire.jpg";
import garden from "images/facilities/garden.jpg";
import restaurant from "images/facilities/restaurant.jpg";
import viewpoint from "images/facilities/viewpoint.jpg";
interface Facility {
  image: string;
  alt: string;
  title: string;
}

const facilities: Facility[] = [
  { image: pool, alt: "Pool", title: "Da Siang Pool" },
  { image: parking, alt: "Parking", title: "Parking" },
  { image: bonfire, alt: "Bonfire", title: "Bonfire" },
  { image: garden, alt: "Garden", title: "Garden" },
  { image: restaurant, alt: "Restaurant", title: "Restaurant" },
  { image: viewpoint, alt: "Viewpoint", title: "Viewpoint" },
];

const FacilityCard: React.FC<Facility> = ({ image, alt, title }) => {
  return (
    <div className="bg-white rounded-xl shadow-lg text-center">
      <img src={image} alt={alt} className="h-48 w-full" />
      <h2 className="text-xl font-bold  my-5 uppercase brown-color">{title}</h2>
    </div>
  );
};

export default function Facilities() {
  return (
    <div className="bg-white px-2 py-5 md:px-20 h-full md:h-screen">
      <div className="title-style-2 mb-5 md:mb-10">
        <p className="font-DosisBold text-center text-xl md:text-3xl uppercase headerRed">
          What we offer
        </p>
        <span className="flex justify-center">
          <span className="border-t-2 border-red w-1/12 mt-2 md:mt-3 lg:mt-4"></span>
        </span>
        {/* <p className="text-center pt-3 font-InterRegular text-lg">
          For your convenience
        </p> */}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
        {facilities.map((facility) => (
          <FacilityCard key={facility.title} {...facility} /> // Spread facility props
        ))}
      </div>
    </div>
  );
}
