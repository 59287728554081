import { zodResolver } from "@hookform/resolvers/zod";
import { FormTag, YalanaForm } from "components/form";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { toast } from "components/ui/use-toast";
import { FeedBackModel, FeedBackModelType } from "Model/feedback-model";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { reviewFields } from "./review-fields";

export const ReviewModals = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm<FeedBackModelType>({
    resolver: zodResolver(FeedBackModel),
    defaultValues: {
      name: "",
      rating: "",
      review: "",
    },
  });

  const onSubmitForm: SubmitHandler<FeedBackModelType> = async (data) => {
    setLoading(true);
    try {
      console.log(data);
      const res = await axios.post(
        "https://booking.peitthemresort.com/api/reviews/newreview",
        data
      );
      if (res.status === 200) {
        toast({
          title: "Feedback Submitted",
          description: `Thank you ${data.name} for your feedback`,
        });
        return onClose();
      }
      toast({
        title: "Failed to submit feedback",
        description: res.statusText,
      });
    } catch (error: any) {
      toast({
        title: "Failed to submit feedback",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setLoading(false);
      form.reset();
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-2xl md:text-4xl">Feedback</DialogTitle>
          <DialogDescription>Please leave your feedback</DialogDescription>
        </DialogHeader>
        <FormTag
          buttonStyle="w-full font-bold"
          form={form}
          onSubmit={onSubmitForm}
        >
          <YalanaForm
            form={form}
            inputFields={reviewFields}
            className="flex flex-col"
            loading={loading}
          />
        </FormTag>
      </DialogContent>
    </Dialog>
  );
};
