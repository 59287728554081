import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInr } from "@fortawesome/free-solid-svg-icons";
import { Button, buttonVariants } from "components/ui/button";
import { Link } from "react-router-dom";
import { ImageGalleryModal } from "components/image-gallery/image-gallery";
import { ImageWithLoader } from "components/image-with-loading";
interface Props {
  label: string;
  rate: string;
  url?: string;
  id: number;
  loading?: boolean;
}

export default function Room({ label, rate, id, url }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <React.Fragment>
      <div className="relative font-InterRegular flex w-full md:max-w-[26rem] flex-col rounded-sm md:rounded-xl bg-white bg-clip-border text-gray-700 shadow-lg">
        <div className="relative mx-4 mt-4 overflow-hidden shadow-lg rounded-xl bg-blue-gray-500 bg-clip-border shadow-blue-gray-500/40">
          <ImageWithLoader
            src={url}
            alt="room_image"
            className="aspect-video"
          />
          <div className="absolute inset-0 w-full h-full to-bg-black-10 bg-gradient-to-tr from-transparent via-transparent to-black/60" />
        </div>
        <div className="p-6">
          <div className="flex items-center justify-between mb-3">
            <h5 className="block text-xl antialiased font-medium leading-snug tracking-normal brown-color">
              {label}
            </h5>
            <p className="flex items-center gap-1.5  text-base font-bold leading-relaxed brown-color antialiased">
              <FontAwesomeIcon icon={faInr} className="text-lg" />
              {rate}
            </p>
          </div>
          {/* <p className="block text-base antialiased font-light leading-relaxed text-gray-700">
            <FontAwesomeIcon icon={faBed} className="mr-1 text-lg" />
            {desc}
          </p> */}
        </div>

        <div className="flex p-6 pt-2 space-x-2">
          <Button
            onClick={() => setOpen(!open)}
            variant={"secondary"}
            className="w-2/6 "
          >
            View
          </Button>
          <Link
            to={{
              search: `?id=${id}`,
              pathname: "/bookroom",
            }}
            className={buttonVariants({
              className: "w-full font-bold bookroom ",
            })}
            // style={{ border: "1px solid #f4608d", color: "#f4608d" }}
          >
            Book Now
          </Link>
        </div>
      </div>
      {open && (
        <ImageGalleryModal id={id} open={open} onClose={() => setOpen(false)} />
      )}
    </React.Fragment>
  );
}
