import React from "react";
import {
  faPhone,
  faEnvelope,
  faHotel,
} from "@fortawesome/free-solid-svg-icons";
import ContactItem from "./ContactItem";

export default function ContactSection() {
  return (
    <div className="min-h-screen contact-background">
      <div className="title-style-2 py-5 md:py-20 text-white">
        <p className="font-DosisBold text-center text-xl md:text-3xl">
          CONTACT US
        </p>
        <p className="text-center pt-3 font-InterRegular text-lg">
          We're Here to Assist You
        </p>
        <hr className="mt-4 border-t border-gray-300" />
      </div>
      <div className="flex flex-col md:flex-row items-center justify-evenly gap-10 mt-5 md:mt-10 px-4 md:px-20">
        <ContactItem
          front={
            <div className="text-center">
              CALL US <br />
              Hover over to see the number
            </div>
          }
          back={<div className="text-center">+91 6009293737 / 7629040065</div>}
          icon={faPhone}
        />
        <ContactItem
          front={
            <div className="text-center">
              EMAIL US <br />
              Hover over to see the email address
            </div>
          }
          back={<div className="text-center">yalana.hotel@rediffmail.com</div>}
          icon={faEnvelope}
        />
        <ContactItem
          front={
            <div className="text-center">
              VISIT US <br />
              Hover over to see the address
            </div>
          }
          back={
            <div className="text-center">
              Peit Them Resort <br />
              Pongtung, Meghalaya 793109
            </div>
          }
          icon={faHotel}
        />
      </div>
    </div>
  );
}
