import React from "react";
import ReviewCard from "./ReviewCard";
import Slider from "react-slick";
import axios from "axios";
import { Button } from "components/ui/button";

import { ReviewModals } from "components/review/feed-back-modals";
interface ReviewT {
  profile: string;
  name: string;
  review: string;
  rating: number;
}
const Review: React.FC = () => {
  const [isReviewModalOpen, setIsReviewModalOpen] =
    React.useState<boolean>(false);
  const [reviews, setReviews] = React.useState<ReviewT[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const getReviews = React.useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://booking.peitthemresort.com/api/reviews/getreviews"
      );
      const data: ReviewT[] = res.data;
      setReviews(data);
    } catch (error) {
      console.error("Failed to fetch reviews", error);
    } finally {
      setLoading(false);
    }
  }, []);
  const handleReViewModals = () => {
    setIsReviewModalOpen(!isReviewModalOpen);
  };

  React.useEffect(() => {
    getReviews();
  }, [getReviews]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col justify-around h-full py-5 space-y-5 md:h-screen bg-slate-100">
      <div>
        <p className="text-xl text-center uppercase font-DosisBold md:text-3xl brown-color">
          Reviews and Ratings
        </p>
        <p className="pt-3 text-lg text-center font-InterRegular">
          Hear What Our Happy Guests Have to Say
        </p>
      </div>
      <div className="py-3 mb-20 h-1/3">
        <Slider {...settings}>
          {reviews &&
            reviews.map((review, index) => (
              <ReviewCard
                key={index}
                photo={review.profile}
                name={review.name}
                review={review.review}
                rating={review.rating}
              />
            ))}
        </Slider>
      </div>
      <div className="flex items-center justify-center w-full">
        <div>
          <Button
            onClick={handleReViewModals}
            disabled={loading}
            variant={"default"}
            className="btn font-DosisMedium px-3 md:px-4 py-1 pre-header hover:bg-gray-800 hover:text-white rounded-xl text-sm"
            size={"lg"}
          >
            Share Your Experience
          </Button>
        </div>
      </div>
      {isReviewModalOpen && (
        <ReviewModals open={isReviewModalOpen} onClose={handleReViewModals} />
      )}
    </div>
  );
};

export default Review;
