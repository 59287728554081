import ContactSection from "./ContactSection";
import Facilities from "./Facilities";
import RoomList from "./RoomList";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeroSection from "./HeroSection";

import React from "react";
import Review from "./Review";

const Home: React.FC = () => {
  return (
    <div className="w-full 2xl:container">
      <HeroSection />
      <Facilities />
      <RoomList />
      <ContactSection />
      <Review />
    </div>
  );
};

export default Home;
